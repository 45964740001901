.container-main {
    overflow: hidden;
}


.chat, .chat-index, .chat-main, .chat-main-message, 
.chat-main-message-body, .chat-sidebar,
.chat-main-dialog-textarea,
.chat-siderbar-items-edit-modal-textarea,
.chat-sidebar * {
    box-sizing: border-box;
}

.chat {
    height: calc(100% - 100px);
}


.chat-sidebar {
    overflow-y: auto;
    width: 240px;
    /* position: fixed; */
    /* top: 0; */
    /* bottom: 0; */
    /* width: 100%;  */
    /* transition: left 0.3s; */
    /* z-index: 1050; */
}

.chat-index {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
/* 
.advertisement-bottom {
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 280px;
    bottom: 0;
    clear: none;
    float: none;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    visibility: visible;
    z-index: 1000;
    transition: bottom 3s ease-out; 
}

.paused {
    transition: none;
}

.adsbygoogle-bottom {
    display: inline-block;
    width: 100%;
    height: 90px;
}

.advertisement-bottom-hidden {
    bottom: -290px;
}

.advertisement-right {
    overflow: hidden;
    position: fixed;
    width: 210px;
    bottom: 10px;
    top: 20%;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    vertical-align: baseline;
    visibility: visible;
    z-index: 1000;
    transition: right 3s ease-out;
}

.advertisement-right-hidden {
    right: -220px;
}

.adsbygoogle-right {
    display: inline-block;
    width: 200px;
    height: 600px;
}

@media (max-width: 375px) {
    .advertisement-right {
        width: 0; 
    }
} */

.chat-sidebar.open {
    left: 0; /* Slide in */
}

.toggle-sidebar-button {
    position: fixed;
    top: 15px;
    left: 10px;
    z-index: 1051; /* Above the sidebar */
}

.btn-select-select {
    min-width: 85px;
}

@media (min-width: 375px) {
    .chat-sidebar {
        width: 100%;
    }

    .chat-sidebar.closed {
        width: 0px;
        overflow-y: hidden;
    }

    .chat-sidebar.open {
        width: 100%;
    }

    .chat-main {
        flex-grow: 1;
    }

    .hide-on-small-screens {
        display: none;
    }

    .text-in-small-screens {
        font-size: small;
    }

    .btn-select-select {
        min-width: 65px;
    }
}

@media (min-width: 768px) {
    .chat-sidebar {
        width: 210px;
    }

    .chat-sidebar.closed {
        width: 210px;
        overflow-y: auto;
    }

    .chat-sidebar.open {
        width: 210px;
    }
    
    .hide-on-small-screens {
        display: block;
    }

    .text-in-small-screens {
        font-size: medium;
    }

    .btn-select-select {
        min-width: 85px;
    }
}

@media (min-width: 1200px) {
    .chat-sidebar {
        width: 240px;
    }

    .chat-sidebar.closed {
        width: 240px;
        overflow-y: auto;
    }

    .chat-sidebar.open {
        width: 240px;
    }
    
    .hide-on-small-screens {
        display: block;
    }

    .text-in-small-screens {
        font-size: medium;
    }

    .btn-select-select {
        min-width: 85px;
    }
}

/* .z-10 {
    z-index: 10;
} */


.chat-main {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-main-message {
    overflow-y: auto; /* 如果内容超出，显示滚动条 */
    overflow-x: hidden;
    flex: 1 0 70%;
    padding-right: 10px;
    padding-top: 10px;
    margin: 10px;
    margin-right: 20px;
    width: 100%;
}

.chat-main-dialog {
    flex: 1 0 30%;
    padding: 10px;
    margin: 10px;
    margin-bottom: 20px;
    max-height: 256px;
    min-height: 0px;
    border-radius: 10px;
    overflow-y: auto;   
}

.chat-main-dialog-translator {
    max-height: 75px; 
}

.chat-main-message, .chat-main-dialog {
    display: flex;
    flex-direction: column; /* 子元素垂直排列 */
    align-items: flex-start; /* 对齐到交叉轴的起点 */
}

.chat-main-message-header {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 20px;
    /* background-color: #f5f5f5;  */
    z-index: 10; /* 确保header在滚动内容之上 */
    display: flex;
    align-items: flex-end; /* 子元素底部对齐 */
    gap: 5px; /* 设置子元素之间的间隔 */
}

.chat-main-message-body {
    overflow-y: auto; /* 如果内容超出，显示滚动条 */
    flex-grow: 1; /* 允许内容区域填充剩余空间 */
    width: 100%;
    padding-right: 3px;
}

.chat-main-message-body-drag {
    border: 2px dashed transparent !important; /* 初始状态下边框透明 */
    transition: border-color 0.3s !important; /* 添加过渡效果 */
}

.chat-divider {
    height: 4px; /* 更高的分隔条，便于拖动 */
    background-color: #ccc;
    cursor: row-resize; /* 拖动时的鼠标样式 */
}

.none-scrollbar {
    overflow-y: auto;
    max-height: 100%;
    overflow-x: hidden;
}

.hidden-scrollbar::-webkit-scrollbar,
.none-scrollbar::-webkit-scrollbar,
.chat-index::-webkit-scrollbar,
.chat-main-dialog-textarea::-webkit-scrollbar,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar,
.chat-sidebar::-webkit-scrollbar,
.chat-main-message-body::-webkit-scrollbar {
    width: 8px;
}

.hidden-scrollbar::-webkit-scrollbar-track,
.none-scrollbar::-webkit-scrollbar-track,
.chat-index::-webkit-scrollbar-track,
.chat-main-dialog-textarea::-webkit-scrollbar-track,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar-track,
.chat-sidebar::-webkit-scrollbar-track,
.chat-main-message-body::-webkit-scrollbar-track {
    background-color: transparent;
}

.hidden-scrollbar::-webkit-scrollbar-thumb,
.none-scrollbar::-webkit-scrollbar-thumb,
.chat-index::-webkit-scrollbar-thumb,
.chat-main-dialog-textarea::-webkit-scrollbar-thumb,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar-thumb,
.chat-main-message-body::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
}

.hidden-scrollbar:hover::-webkit-scrollbar-thumb,
.chat-main-dialog-textarea:hover::-webkit-scrollbar-thumb,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar-thumb,
.chat-sidebar::-webkit-scrollbar-thumb,
.chat-main-message-body:hover::-webkit-scrollbar-thumb {
    background-color: #ccc;
}

.hidden-scrollbar::-webkit-scrollbar-thumb:hover,
.chat-main-dialog-textarea::-webkit-scrollbar-thumb:hover,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar-thumb:hover,
.chat-sidebar::-webkit-scrollbar-thumb:hover,
.chat-main-message-body::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

.hidden-scrollbar::-webkit-scrollbar-thumb:vertical,
.chat-main-dialog-textarea::-webkit-scrollbar-thumb:vertical,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar-thumb:vertical,
.chat-sidebar::-webkit-scrollbar-thumb:vertical,
.chat-main-message-body::-webkit-scrollbar-thumb:vertical {
    border-radius: 4px;
}

.hidden-scrollbar::-webkit-scrollbar-thumb:horizontal,
.chat-main-dialog-textarea::-webkit-scrollbar-thumb:horizontal,
.chat-siderbar-items-edit-modal-textarea::-webkit-scrollbar-thumb:horizontal,
.chat-sidebar::-webkit-scrollbar-thumb:horizontal,
.chat-main-message-body::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
}

.avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    /* border: 1px solid ; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
}

.avatar img {
    width: 100%; /* 确保图像填满容器 */
    height: auto; /* 保持图像的纵横比 */
}

.avatar:hover {
    cursor: pointer; /* 鼠标移入时改变为手指图标 */
}
  
.avatar span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-title-col {
    margin-right: 16px;
    padding-left: 16px;
}

.chat-title {
    margin-bottom: 6px;
    padding-bottom: 6px;
}

.border-bottom-only {
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important; /* 这是Bootstrap默认的边框颜色 */
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-f   ill-available;
}

main {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

.dropdown-toggle { outline: 0; }

.btn-toggle {
    padding: .25rem .5rem;
    font-weight: 600;
    color: var(--bs-emphasis-color);
    background-color: transparent;
}

.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(var(--bs-emphasis-color-rgb), .85);
    background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(var(--bs-emphasis-color-rgb), .85);
}
.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: var(--bs-tertiary-bg);
}

.scrollarea {
    overflow-y: auto;
}

.font-italic {
    font-style: italic;
}

.font-bold {
    font-weight: bold;
}

.no-border-button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none !important; /* 移除边框 */
    background-color: transparent !important; /* 设置背景颜色为透明 */
    box-shadow: none !important; /* 移除阴影效果 */
    color: inherit !important; /* 继承文字颜色 */
}

.no-border-button:hover,
.no-border-button:focus {
    background-color: transparent !important; /* 悬停和聚焦时保持背景透明 */
    border: none !important; /* 悬停和聚焦时保持无边框 */
    box-shadow: none !important; /* 悬停和聚焦时保持无阴影 */
    color: inherit !important; /* 继承文字颜色 */
}

.no-border-card {
    border: none !important;
    box-shadow: none !important;
}
  
.no-border-card-header,
.no-border-card-body,
.no-border-card-footer {
    /* border: none !important; */
    padding: 0.25rem !important;
}

.avatar-top {
    margin-top: 0.25rem; /* 或者你需要的任何值 */
}

.card-receiver, .card-sender {
    border-radius: 10px; /* 你可以根据需要调整这个值 */
    margin-top: 10px;
    margin-bottom:10px;
    border-radius: 10px; /* 你可以根据需要调整这个值 */
}

/* Webkit 浏览器 */
pre::-webkit-scrollbar,
code::-webkit-scrollbar {
    height: 10px;
}

pre::-webkit-scrollbar-thumb,
code::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 6px; /* 如果你想要圆角滑块，可以设置边界半径 */
}

/* Firefox 浏览器 */
pre,
code {
    scrollbar-color: #bbb  #f0f0f0; 
    scrollbar-width: thin; /* 设置滚动条的宽度 */
}



.icon-enlarged {
    transform: scale(1.5);
}

.chat-main-message-body-card-describe {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5; 
    z-index: 10; /* 确保header在滚动内容之上 */
    border-radius: 10px; /* 你可以根据需要调整这个值 */
}

.col-avatar {
    /* min-width: 30px !important; */
    flex-shrink: 0; /* 防止.col-avatar缩小 */
}

.col-fill-rest {
    flex-grow: 1;
}

.form-check-input:checked {
    background-color: #198754 !important;
    border-color: #198754 !important;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.5) !important;
    border-color: #28a745 !important;
}


.chat-main-dialog{
    position: relative;
}

.chat-main-dialog-container,
.chat-main-dialog-container-row,
.chat-main-dialog-container-col {
    height: 100%;
    width: 100%;
    padding-right: 0 !important;
    border-radius: 10px;
    min-height: 0;
}

.chat-main-dialog-textarea {
    height: 100%;
    width: 100%;
    overflow-wrap: break-word;
    resize: none;
    z-index: auto;
    transform: none;
    padding: 10px;
    border-radius: 10px;
    padding-right: 55px !important;
}


.chat-main-dialog-textarea:focus {
    /* box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5) !important; */
    box-shadow: none;
    border-color: rgba(108, 117, 125, 0.5);
    
}

.chat-main-dialog-container {
    overflow: hidden;    
    /* padding-right: 5px; */
}

.chat-main-dialog-container-row {
    background-color: #fff;
}



.chat-main-dialog-container-col,
.chat-main-dialog-container-col-left,
.chat-main-dialog-container-col-right {
    background-color: #fff;
}

.chat-main-dialog-container-col-right {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
}

.button-send {
    display: flex !important;
    justify-content: center;
    position: absolute;
    bottom: 25px;
    right: 40px;
}

.button-pan {
    display: flex !important;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    right: 40px;
}

.chat-image,
.chat-image-button-group {
    max-width: 350px;
    max-width: 350px;
    width: 100%;
    height: auto;
    margin: 5px;
}

.chat-image-button-group {
    display: flex; /* 启用Flexbox */
    justify-content: flex-start; /* 子元素向行的起始位置对齐 */
}

.chat-image-button-group > .btn {
    flex: 1; /* 所有按钮平均分配空间 */
    max-width: calc(100% / 5); /* 第二个ButtonGroup的按钮宽度为其父元素宽度的1/4 */
}

.image-container {
    display: flex; /* 启用Flexbox */
    flex-direction: column; /* 子元素垂直排列 */
    align-items: flex-start; 
    width: 100%;
}

.chat-main-dialog-body-pan-container{
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    width: 60%;
    height: 80%;
}

.chat-main-dialog-body-pan-modal-body {
    height: 70vh; /* 或者其他你想要的高度 */
    overflow-y: auto; /* 如果内容超出高度，显示滚动条 */
}

.preserve-whitespace {
    white-space: pre-wrap;
}

.chat-new-item-tools-bar {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.chat-siderbar-items-edit-modal {
    height: 60vh;
    width: 60vh;
}

.chat-siderbar-items-edit-modal-body {
    height: 100%;
    width: 100%;
}

.chat-siderbar-items-edit-modal-row {
    width: 100%;
}

.chat-siderbar-items-edit-modal-input:focus {
    /* box-shadow: none; */
    box-shadow: 0 0 10px 0.25rem rgba(108, 117, 125, 0.5);
    border-color: rgba(108, 117, 125, 0.5);
}

.chat-siderbar-items-edit-modal-input {
    width: 100%;
    overflow-wrap: break-word;
    resize: none;
    z-index: 1;
    transform: none;
    padding: 10px;
    border-radius: 10px;
}

.chat-siderbar-items-edit-modal-textarea {
    height: 100%;
    width: 100%;
    overflow-wrap: break-word;
    resize: none;
    z-index: auto;
    transform: none;
    padding: 10px;
    border-radius: 10px ;
}

.input-unfinish {
    box-shadow: #f8d7da;
    box-shadow: 0 0 5px 0.25rem #f8d7da;
    border-color: #f8d7da;
}

.chat-siderbar-items-edit-modal-textarea:focus {
    border-color: rgba(108, 117, 125, 0.5) !important;
    box-shadow: 0 0 10px 0.25rem rgba(108, 117, 125, 0.5) !important;

}

.range-temperature:focus{
    border-color: rgba(108, 117, 125, 0.5) ;
    box-shadow: 0 0 10px 0.25rem #6C757D80;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* 移除默认样式 */
    appearance: none;
    background: #6c757d; /* 滑块的背景颜色 */
    cursor: pointer; /* 鼠标悬停时的光标样式 */
    height: 15px; /* 滑块的高度 */
    width: 15px; /* 滑块的宽度 */
    border-radius: 50%; /* 如果你想要圆形滑块 */
}

/* 图片容器 */
.carousel-container .active-slide img {
    transform: scale(1.1);
    box-shadow: 0 0 10px 0.25rem #6C757D80;
}
  
.carousel-container img {
    max-width: 100%;
    display: block;
    margin: auto;
    transition: transform 0.5s ease, box-shadow 0.5s ease;    
}
  
.chat-main-message-body-file-container {
    height: 100vh; 
    overflow-y: auto;
}

.chat-main-message-body-file-container-body {
    height: 100%;
}

.button-send-file {
    display: flex !important;
    justify-content: center;
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.chat-main-message-body-container-textarea {
    height: 100%;
    width: 100%;
    overflow-wrap: break-word;
    resize: none;
    z-index: auto;
    transform: none;
    padding: 10px;
    padding-right: 60px;
    border-radius: 10px ;
}

.chat-main-message-body-container-textarea:focus {
    border-color: rgba(108, 117, 125, 0.5) !important;
    box-shadow: 0 0 10px 0.25rem rgba(108, 117, 125, 0.5) !important;
}

.modal-control-input:focus {
    border-color: rgba(108, 117, 125, 0.5) !important;
    box-shadow: 0 0 10px 0.25rem rgba(108, 117, 125, 0.5) !important;
}

/* CSS 类来调整按钮内的图标和文字布局 */
.btn-mj-size-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-mj-model-icon {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
}

.btn-mj-icon-text {
    margin-left: 0.5rem;
}
  
  /* 调整图标的大小和间距 */
.btn-mj-icon-text .bi {
    font-size: 1.5rem; /* 调整图标大小 */
    /* margin-bottom: 0.05rem;  */
}
  
.icon-90 {
    transform: rotate(90deg);
}

.icon-45 {
    transform: rotate(45deg);
}

.chat-card-footer {
    margin-left: 15px;
}

.chat-index-card {
    height: 160px;
    padding: 10px;
    border-color: rgba(108, 117, 125, 0.5) !important;
    box-shadow: 0 0 12px 0.25rem rgba(108, 117, 125, 0.5) !important;
    /* cursor: pointer; */
}


.check-in-card .card-body, .check-in-card-checked .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    padding-bottom: 0px;
}


.check-in-card, .check-in-card-checked {
    height: 80px;
    padding: 0px;
    padding-top: 45px;
    margin-bottom: 0.5rem; /* 调整间距 */
    font-size: 1.1rem;
}

.check-in-card, .check-in-card-checked {
    width: 80px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 7px;
    margin-right: 7px;
}

.check-in-card::before,
.check-in-card-checked::before {
    content: attr(data-day);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5); 
    font-size: 2rem;
    z-index: 0;
    padding-bottom: 15px;
}

.check-in-card::before {    
    color: rgba(0, 0, 0, 0.1); 
}

.check-in-card-checked::before {
    color: #9da3ac; 
}

.check-in-card-checked {
    border-color: rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 12px 0.25rem rgba(108, 117, 125, 0.5);
}

.check-in-card-body {
    position: relative;
    z-index: 1; /* 确保卡片内容显示在水印之上 */
}

.check-in-font-title {
    font-size: 1.2rem;
}

.check-in-font-text {
    font-size: 1.5rem;
}

.chat-photomaker-button-upload {
    height: 210px;
    width: 210px;
    margin: 10px;
    border: 1px dashed rgb(108, 117, 125); /* 2px宽的黑色虚线边框 */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover; /* 图片覆盖整个按钮，保持宽高比 */
    background-position: center; /* 图片居中显示 */
    background-repeat: no-repeat; /* 不重复图片 */
    flex-direction: column;
}

.chat-photomaker-layout-draw {
    margin-left: 10px;
}

.chat-photomaker-layout-show {
    width: 160px; /* 设置图片容器的宽度 */
    height: 160px; /* 设置图片容器的高度，与宽度相等形成正方形 */
    object-fit: none; /* 不调整图片大小，保持原始尺寸 */
    object-position: center; /* 从左上角开始显示图片 */
    overflow: hidden; /* 隐藏超出容器的部分 */
}

.chat-photomaker-header {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 20px;
    display: flex;
    align-items: flex-end; /* 子元素底部对齐 */
    gap: 5px; /* 设置子元素之间的间隔 */
}

.chat-photomaker-message {
    overflow-y: auto; /* 如果内容超出，显示滚动条 */
    overflow-x: hidden;
    flex: 1 0 70%;
    padding-right: 10px;
    margin-right: 20px;
    width: 100%;
}

.describ-align-center {
    display: flex;
    align-items: flex-start; /* 垂直居中 */
}
  
.image-mj-actions {
    position: absolute;
    display: flex;
    overflow: hidden;
    right: 35px;
    bottom: 35px;
}

.image-mj-button {
    transform: translateX(100%);
    transition: transform 0.3s ease;
}

.image-mj-icon {
    font-size: 1.2rem;
    color: #2b2f32;
    background-color: #fff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.chat-mj-image-expand-button-upload {
    height: 210px;
    width: 210px;
    margin: 10px;
    border: 1px dashed rgb(108, 117, 125); /* 2px宽的黑色虚线边框 */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover; /* 图片覆盖整个按钮，保持宽高比 */
    background-position: center; /* 图片居中显示 */
    background-repeat: no-repeat; /* 不重复图片 */
    flex-direction: column;
    z-index: 100;
}


.chat-mj-image-expand-button-delete {
    position: absolute;
    top: -12px;
    right: -12px;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    color: #495057;
    background-color: #ced4da;
    display: none;
}

.chat-mj-image-expand-button-delete:hover,
.chat-mj-image-expand-button-delete:focus {
    background-color: transparent ; 
    box-shadow: none ;
    background-color: black;
    color: white;
}

.chat-mj-image-expand-button-container {
    position: relative;
    width: 100%; /* 填满父容器 */
    height: 100%; /* 填满父容器 */
}

.chat-mj-image-expand-button-container:hover .chat-mj-image-expand-button-delete {
    display: flex;
}

.chat-mj-image-expand-button-actions {
    position: absolute;
    display: flex;
    overflow: hidden;
    right: 2px;
    bottom: 2px;
}

.chat-main-message-body-media-container {
    overflow: hidden;
}

.media-play-on-image {
    position: absolute;
    border-radius: 50%;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
}

.media-lyric-tooltip .tooltip-inner {
    /* 设置 Tooltip 内部背景色为透明 */
    background-color: #dee2e6 ; 
    max-width: 600px;
    max-height: 2000px;
}

.media-lyric-tooltip .media-lyric-tooltip::before {
    border-top-color: #dee2e6 ; /* 假设 Tooltip 是在顶部显示的 */
}