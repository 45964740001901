/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
} */

.avatar, .avatar .avatar-img, .avatar .avatar-text {
    border-radius: 50%;
}

.avatar .avatar-text {
    /* background: #FA720C; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    line-height: 0;
    height: 100%;
    width: 100%
}

.icon-1-2x {
    font-size: 1.2em;
}

.icon-1-5x {
    font-size: 1.5em;
}


.avatar .avatar-text-user {
    background-color: #198754;
}

.avatar-xl>.avatar-text {
    font-size: 28.275862069px;
}

.profile-body {
    text-align: center;
    padding-bottom: 1.25rem;
}

.avatar-xl {
    height: 82px !important;
    width: 82px !important;
}

.profile .avatar {
    border: 4px solid #fff;
    margin-left: auto;
    margin-right: auto;
}

.profile .avatar.avatar-xl {
    margin-top: -53px;
    margin-bottom: 8.2px;
}

.clickable {
    cursor: pointer;
}

.list-container {
    height: 400px;
    overflow: hidden;
    display: flex;
}

/* 确保 Col 组件填满 list-container 的高度 */
.list-container > .col-6 {
    display: flex;
    flex-direction: column;
    padding: 10px; /* 如果需要，移除内边距 */
    max-height: 100%;
}

/* 设置 Card 组件以填满 Col 组件的高度 */
.card {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.list-group-chat {
    padding-right: 5px;
}

.list-chat {
    padding-right: 5px;
}

/* 隐藏滚动条，但允许滚动 */
.list-group-chat, .list-chat {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
}

/* 自定义滚动条样式 */
.list-group-chat::-webkit-scrollbar {
    width: 8px; /* 滚动条宽度 */
}

.list-group-chat::-webkit-scrollbar-track {
    background-color: transparent;
}

.list-group-chat::-webkit-scrollbar-thumb {
    background-color: #999;/* 滚动条颜色 */
    border-radius: 8px; /* 滚动条圆角 */
}

.list-group-chat::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* 鼠标移入时滚动条颜色变深 */
}

.list-group-chat::-webkit-scrollbar-thumb:vertical {
    border-radius: 4px;
}

.list-group-chat::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
}

.btn-inline {
    width: auto;
    display: inline-block;
}

.hr-or-container {
    display: flex;
    align-items: center;
}

.hr-or {
    width: 30%;
    margin: 0 10px;
}

.row-logo {
    height: 8vh;
}

/* .row-login {
    align-items: center;
} */
  
.login-logo {
    width: auto;
    height: 60px;
}

.login-logo-show {
    width: 100%;
}

.login-QRCode {
    width: 240px;
    height: 240px;
    border-radius: 10px;
}

.login-title {
    margin-bottom: 50px;
}

.container-background {
    overflow-y: hidden !important;
    width: auto;
    height: 80vh;
    /* 设置背景图 */
    /* background-image: url('../public/login-show.gif');  */
    background-size: cover; /* 覆盖整个容器 */
    background-position: center; /* 居中背景图 */
    background-repeat: no-repeat; /* 不重复背景图 */
    position: relative; /* 相对定位，作为子元素绝对定位的参考 */
  }
  
.content {
    position: absolute; /* 绝对定位 */
    top: 50%; /* 垂直居中 */
    left: 50%; /* 水平居中 */
    transform: translate(-50%, -50%); /* 使用 transform 来精确居中 */
    z-index: 1; /* 确保内容在背景之上 */
    text-align: center; /* 文本居中 */
    /* 添加其他样式以满足你的设计需求 */
}

.login-container {
    overflow: hidden;
}

.profile-cs-tooltip .tooltip-inner {
    /* 设置 Tooltip 内部背景色为透明 */
    background-color: #dee2e6 !important; 
    max-width: 500px;
    max-height: 500px;
}

.profile-cs-tooltip .tooltip-arrow::before {
    border-top-color: #dee2e6 !important; /* 假设 Tooltip 是在顶部显示的 */
}
