.file-word {
    color: #2B579A; /* Word 文件的蓝色 */
}

.file-excel {
    color: #217346; /* Excel 文件的绿色 */
}

.file-ppt {
    color: #D24726; /* PowerPoint 文件的红色 */
}

.file-richtext {
    color: #5B9BD5; /* Rich Text 文件的浅蓝色 */
}

.file-pdf {
    color: #FF0000; /* PDF 文件的红色 */
}

.file-text {
    color: #000000; /* 文本文件的黑色 */
}
