.main-container {
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
}

.main {
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
}

.container {
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
}

.py-6 .py-lg-12 {
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
}

.message-header {
    line-height: 1.3;
    margin-top: 0.25rem;
}

.message .message-header {
    display: flex;
    justify-content: space-between;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d!important;
}

.extra-small {
    font-size: .865em;
}

.chat-body-inner {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    padding-right: 10px; /* 添加右侧内边距 */
}

/* 隐藏滚动槽 */
.chat-body-inner::-webkit-scrollbar-track {
    background: none;
    border: none;
}
  
/* 设置滚动条样式 */
.chat-body-inner::-webkit-scrollbar {
    width: 10px; /* 滚动条宽度 */
}

/* 设置滚动条圆角边缘 */
.chat-body-inner::-webkit-scrollbar-thumb {
    background-color: #d3d4d5; /* 滚动条颜色 */
    border-radius: 5px; /* 圆角边缘 */
    border: none;
}

/* 鼠标悬停在滚动条上时的样式 */
.chat-body-inner::-webkit-scrollbar-thumb:hover {
    background-color: #6c757d;
}

.message-text {
    color: black;
}

.icon-0-5x {
    font-size: 0.5em;
}

.icon-0-6x {
    font-size: 0.6em;
}

.icon-0-7x {
    font-size: 0.7em;
}

.icon-1-2x {
    font-size: 1.2em;
}

.icon-1-5x {
    font-size: 1.5em;
}

.copy-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.custom-tooltip .tooltip-inner {
    text-align: left;
}

.btn.border-0:focus, .btn.border-0:hover {
    box-shadow: none;
}

.custom-nav-link {
    color: #198754; /* 用您喜欢的颜色替换 */
}

.custom-nav-link:hover {
    color: #07573c; /* 用您喜欢的点击后的颜色替换 */
}

.custom-nav-link:active {
    color: #07573c; /* 用您喜欢的点击后的颜色替换 */
}

.main-header {
    overflow: visible;
}
  