/* Switch.css */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
    margin-left: 10px;
}
  
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

  /* Switch.css */
.switch input:checked + .slider {
    background-color: var(--bs-success); /* 使用 Bootstrap 的 success 颜色 */
}  
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 28px;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}
  
input:checked + .slider {
    background-color: var(--bs-success);
}
  
input:focus + .slider {
    box-shadow: 0 0 1px var(--bs-success)
}
  
input:checked + .slider:before {
    transform: translateX(20px);
}
  
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}
  