/* 在 CSS 中添加一个新的类 */
/* .advertisement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 280px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; 
} */

.advertisement-checkin {
    display: block;
    justify-content: center;
    text-align: center;
}

.advertisement-bottom {
    display: block;
    justify-content: center;
    text-align: center;
}


/* 
.advertisement-bottom {
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 280px;
    bottom: 0;
    clear: none;
    float: none;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    visibility: visible;
    z-index: 1000; 
    transition: bottom 3s ease-out; 
} */

/* 暂停动画的类 */
.paused {
    transition: none;
}

.adsbygoogle-bottom {
    display: inline-block;
    width: 90%;
    height: 120px;
    bottom: 0;
    position: absolute;
}

.advertisement-bottom-hidden {
    bottom: -290px;
}

.advertisement-right {
    overflow: hidden;
    position: fixed;
    width: 210px;
    bottom: 10px;
    top: 20%;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    vertical-align: baseline;
    visibility: visible;
    z-index: 1000; /* 确保广告在其他元素之上 */
    transition: right 3s ease-out; /* 可选的过渡效果 */
}

.advertisement-right-hidden {
    right: -220px;
}

.adsbygoogle-right {
    display: inline-block;
    width: 200px;
    height: 600px;
}

/* 当屏幕宽度小于或等于 375px 时 */
@media (max-width: 375px) {
    .advertisement-right {
        width: 0; 
    }
}